import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'

import Badge from '@/components/base/Badge'
import CustomText, { TextSize } from '@/components/base/CustomText'
import CustomIcon, { IconsTypes } from '@/components/base/Icons/CustomIcon'

type Props = {
  /**
   * Button Label
   */
  label?: string
  /**
   * Content
   */
  children?: ReactNode | string
  /**
   * Check if href is exact route
   */
  exact?: boolean
  /**
   * href to redirect
   */
  href?: string
  /**
   * Icon for Button
   */
  iconType?: IconsTypes
  /**
   * Badge string, if true, show badge
   */
  badgeContent?: string
}

type WrapperProps = {
  /**
   * Href for link
   */
  href?: string
  /**
   * Content
   */
  children?: ReactNode | string
  /**
   * Use next link wrapper
   */
  useLink?: boolean
  /**
   * ClassNames
   */
  className?: string
}
const LeftBarMenuItem = ({
  children,
  label,
  exact = false,
  href,
  iconType,
  badgeContent = undefined,
  item,
}: Props): JSX.Element => {
  const { pathname } = useRouter()

  const active = exact && !children ? pathname === href : pathname.indexOf(href as string) > -1
  const isActive = item?.pathname ? item?.pathname === pathname : active

  return (
    <li className="flex flex-row items-center justify-start gap-8 h-full">
      {isActive ? (
        <div className="bg-primary hidden xl:block rounded-r-lg h-full w-4">
          <div className="opacity-0">a</div>
        </div>
      ) : (
        <div className="w-4 hidden xl:block" />
      )}
      <Wrapper useLink={!!href} href={href} className="w-full py-2 relative">
        <div className="flex flex-row items-center justify-start gap-3">
          <div className="relative m-auto xl:m-0">
            {badgeContent ? <Badge label={badgeContent} /> : null}
            <CustomIcon name={iconType} className={clsx(active ? 'text-primary' : 'text-gray')} size={24} />
          </div>

          <CustomText size={TextSize.SubTitle2} className="text-gray hidden xl:block">
            {label}
          </CustomText>
        </div>
      </Wrapper>
    </li>
  )
}

const Wrapper = ({ children, href, useLink, className }: WrapperProps) => {
  if (useLink) {
    return (
      <Link
        href={href as string}
        className={clsx('inline-block rounded-md px-2 h-full hover:bg-gray-200 hover:bg-opacity-50 ', className)}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <div
        className={clsx(
          'inline-block h-full rounded-md px-2 hover:bg-gray-200 hover:bg-opacity-50 cursor-pointer',
          className
        )}
      >
        {children}
      </div>
    )
  }
}

export default LeftBarMenuItem
